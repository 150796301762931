import React from "react";
import "./App.css";

import {QualifiedElectronicSignature} from "@trustservices/smart-flow-web-components";

function App() {
    return (
        <div className="App">
            <QualifiedElectronicSignature
                fullMode={true}
                env={"prod"}
            />
        </div>
    );
}

export default App;
